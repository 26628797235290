import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { contentActions } from '../../../actions'

import { withRouter } from 'react-router-dom'
//import ContentStore from '../../../stores/ContentStore'
//import ContentStoreActions from '../../../actions/ContentStoreActions'

import TeaserList from '../teaserList'
import TextImage from '../TextImage'
import MultiGallery from '../MultiGallery'
import Gallery from '../Gallery'
import Text from '../Text'
import PdfForm from '../pdfForm'
import Location from '../Location'
import Team from '../Team'
import Images from '../images'
import Contact from '../contact'
import NextProject from '../nextProject'

import TeaserCategory from '../teaserCategory'
import Logos from '../logos'
import ContactTeaser from '../contactTeaser'
import Media from '../media'
import Text2 from '../text2'
import TextImage2 from '../textImage2'

const components = {
    teaserList: TeaserList,
    textImage: TextImage,
    multiGallery: MultiGallery,
    gallery: Gallery,
    text: Text,
    pdfForm: PdfForm,
    location: Location,
	team: Team,
	images: Images,
	contact: Contact,
	nextProject: NextProject,
	teaserCategory: TeaserCategory,
	logos: Logos,
	contactTeaser: ContactTeaser,
	media: Media,
	text2: Text2,
	textImage2: TextImage2
}

function Page({ style, location, history, page, getPage }) {
    const [ data, setData ] = useState(null)

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const url = params.get('page')

        getPage(url)
    })

    useEffect(() => {

        if(page) {
            setData(page)
            console.log('setdata')
        }


    }, [page])

    let content
	
	if(data) {
		
		content = data.content.map((data, idx) => { 

			const Content = components[data.type];
			return <Content key={idx} data={data} style={style} />;
		});
		
	}

    return (
        <div style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', overflow: 'auto'}}>
            <div className="table" style={{height: '100%'}}>
                <div className="col" style={{verticalAlign: 'middle'}}>
                {content}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state, props) => {
    const { pages, style } = state.content

    const params = new URLSearchParams(props.location.search)
    const url = params.get('page')
    
    const page = pages.find(page => page.path === url) 
    
    return {
        style,
		page
	}
}

const mapDispatchToProps = dispatch => ({
    getPage: (url) => dispatch(contentActions.getPage(url)),
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Page))