import React, { useState, useEffect } from 'react'
import styles from './Overlay.module.sass'
import { useLocation, useHistory } from 'react-router-dom'

import Gallery from './Gallery'
import Page from './Page'

let scrollTop = null

function Overlay({ active, type }) {
    const [visible, setVisible] = useState(false)
    const [fullscreen, setFullscreen] = useState(false)
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        if (active && !visible) {

            scrollTop = window.scrollY || window.pageYOffset;

            let height = document.documentElement.scrollHeight;
            //console.log(height, scrollTop, window.innerHeight)

            let test = (scrollTop + window.innerHeight / 2) / height * 100
            //console.log(test)

            let styleTest = `transform-origin: center ${test}%; position: fixed; width: 100%; top: -${scrollTop}px`;

            //console.log(styleTest)

            document.getElementById('page').style.cssText = styleTest;
            document.getElementById('page').classList.add("test");

            setVisible(true)
            setFullscreen(false)
        }

        if (!active && visible) {

            document.getElementById('page').classList.remove("test");

            setTimeout(() => {
                setVisible(false)
                document.getElementById('page').style.cssText = ''
                window.scroll(0, scrollTop)
                setFullscreen(false)
            }, 500);
        }
    }, [active, visible])


    const handleClose = () => {
        setTimeout(function () {

            if (document.fullscreen) {

                setTimeout(function () {

                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.webkitExitFullscreen) {
                        document.webkitExitFullscreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }


                }.bind(this), 400)

            }


        }.bind(this), 400)

        history.push(location.pathname)
    }

    const handleFullScreen = () => {
        if (!document.fullscreen) {

            var elem = document.documentElement;

            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }

            setFullscreen(true)

        }
    }

    if (!visible) return null

    //console.log('render overlay')
    //console.log(document.fullscreen)

    return (
        <div className={styles.overlay + (active ? ' in' : ' out')}>
            <div className="actions">
                {(type === 'gallery' && !fullscreen) &&
                    <span className="fullscreen" onClick={() => handleFullScreen()}>Fullscreen</span>
                }
                <span className="close" onClick={() => handleClose()}></span>
            </div>
            {type === 'gallery' &&
                <Gallery />
            }
            {type === 'page' &&
                <Page />
            }

        </div>
    )
}

export default Overlay;