import React, { Component } from 'react'
import Task from './Task'
import styled from 'styled-components'

import { Droppable } from 'react-beautiful-dnd'

const TaskList = styled.div`
    width: 200%;
	min-height: 100px;
	display: inline-flex;
`

class Column extends Component {
	render() {
		return (
			<Droppable droppableId={this.props.column.id} direction="horizontal">
				{(provided, snapshot) => (
					<TaskList
						innerRef={provided.innerRef}
						ref={provided.innerRef}
						{...provided.droppableProps}
						isDraggingOver={snapshot.isDraggingOver}	
					>	
						{this.props.tasks.map((task, index) => (
							<Task key={task.id} task={task} index={index} sizes={this.props.sizes} removeImage={image => this.props.removeImage(image) } />
						))}
						{provided.placeholder}
					</TaskList>
				)}
			</Droppable>
		)
	}
}

export default Column;
