import React, { useState, useEffect, useRef, useMemo } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { contentActions, pdfActions } from 'actions'
import { withRouter } from 'react-router-dom'

//import LazyLoad from 'react-lazy-load'

//import ContentStore from '../../stores/ContentStore'
//import ContentStoreActions from '../../actions/ContentStoreActions'
//import PdfStore from '../../stores/PdfStore'
//import PdfStoreActions from '../../actions/PdfStoreActions'

import Teaser from './teaserList/Teaser'
import styles from './teaserList/Teaser.module.sass'

import Icon from '../icon/Icon'
import LazyImage from '../LazyImage'
import ContactTeaser from './contactTeaser'

import { ScaleSize } from '../../Functions'

function MultiGallery({data, style, view, setGallery, addRemoveImage, galleryImages, pdfImages, location, history, language}) {
	const [ viewType, setViewType ] = useState(data.startView ? data.startView : view ? view : 'grid') 
	const [ cols, setCols ] = useState(null)
	const [ touchedID, setTouchedID ] = useState(null) 

	const allImages = useMemo(
		() => {
			let allImagesTemp = []

			if(data.customGallery) return data.customGallery

			data.galleries.forEach(gallery => {	    
				gallery.content.forEach(image => {
					allImagesTemp.push(image)
				})
			})
			//console.log('get all')
			return allImagesTemp
		},
		[],
	);

	//const [ allImages, setAllImages ] = useState(allImagesTemp) 

	//const location = useLocation()
	//const history = useHistory()
	//const query = useQuery();
	const component = useRef()

	useEffect(() => {
		resize()
		window.addEventListener('resize', resize)

		const query = new URLSearchParams(location.search)

		if(query.get('gallery') && !galleryImages.length) { 
			setGallery(allImages)
		}
		
		return () => {
			window.removeEventListener('resize', resize)
        }
	}, [])

	const resize = () => {
		const width = component.current.clientWidth
		const cols = width > 900 ? 3 : width >= 320 ? 2 : 1
		setCols(cols)
	}

 	const clickImage = (number) => {
 		//console.log(number);

		setGallery(allImages)

 		let image = allImages[number]

 		//this.context.router.history.push( ParamAdd(this.props.location, 'gallery=' + image.ID))
	 
		history.push({...location, search: `?gallery=${image.ID}` })
	}

 	const handleTouch = (ID) => {
 		//console.log(ID);

 		const newTouchedID = touchedID === ID ? null : ID 
		setTouchedID(newTouchedID);
 	}

 	const clickTeaser = (images, number) => {
 		//console.log(number);

 		setGallery(images)

 		let image = images[number]

 		history.push({...location, search: `?gallery=${image.ID}` })


 	}

 	const addImage = (image) => {
 		//console.log(image.ID);
		//PdfStoreActions.addImage(image);
		addRemoveImage(image)
 	}

	let scale = style.scale
	//content = [],
	//colHeights = []

	//console.log(data)
	

	style = {...style,
		p: {
			fontSize: ScaleSize(28, 18, scale),
			lineHeight: ScaleSize(36, 26, scale)
		}
	}

	//console.log(this.state.content)

	const teaserView = useMemo(
		() => {
			//console.log('create teaserView')
			

			const content = data.galleries.map((gallery, i) => {

				const data = {
					...gallery, 
					image: gallery.image ? gallery.image : gallery.content[0] ? gallery.content[0]['thumbnailUrl'] : ''
				}

				const onClick = data.forceOverlay || !gallery.url ? () => clickTeaser(gallery.content, 0) : null

				return <Teaser key={i} data={data} style={style} onClick={onClick} index={i} />
			})


			if(data.contactTeaser) content.splice(4, 0, 
				<ContactTeaser 
					key={'contact'}
					style={style} 
					data={{
						text: data.contactTeaserText,
						target: data.contactTeaserTarget,
						newTab: data.contactTeaserNewTab,

						color: data.contactTeaserColor,
						fontcolor: data.contactTeaserFontcolor,
						colorMouseover: data.contactTeaserColorMouseover,
						fontcolorMouseover: data.contactTeaserFontcolorMouseover,
					}} 
					/> );

			return (<div className={styles.teaser}>
				{content}
			</div>)
			
		},
		[component.current, style.scale]
	);


	const gridView = useMemo(
		() => {
			//console.log('try create gridView')
			if(component.current) {

				//console.log('create gridView')
				const addY = component.current.getBoundingClientRect().y + window.scrollY

				const content = []
				const colHeights = []

				for(let i=0; i<cols; i++) {
					colHeights.push(0)
				}

				const componentWidth = component.current.clientWidth
				const space = 0.03 *componentWidth
				const colWidth = (componentWidth - (cols-1) * space) / cols
		
				allImages.forEach((image, i) => {
					
					let currentCol = colHeights.reduce((iMin, x, i, arr) => x < arr[iMin] ? i : iMin, 0),
					left = currentCol * (colWidth + space),
					top = colHeights[currentCol],
					width = colWidth,
					height = image['height']/image['width']*width,
					pdf = pdfImages.findIndex(entry => entry.ID === image.ID) > -1 ? true : false 
					
					colHeights[currentCol] += height + space
		
					let icon = image.vimeo_url ? 'play' : 'lens'
					let touched = touchedID === image.ID

					content.push(
						<div
							key={'g'+i}
							className={'image aniResize' + (touchedID=== image.ID ? ' touched' : '')} 
							style={{
								transform: `translate(${left}px, ${top}px)`, 
								width: `${width}px`,
								height: `${height}px`,
								backgroundColor: image.color
							}}>
							
							<LazyImage top={top+addY} url={image.thumbnailUrl} alt={image.caption} video={image.video} /> 
							
							<span className="hoverArea aniHover" onClick={() => clickImage(i)} 
								onTouchStart={e => handleTouch(image.ID)}
								onTouchEnd={e => e.preventDefault()}
							/>
							<span 
								className="icon main lens middle aniHover"
								style={{width: style.icon.width, height: style.icon.width}}
								onClick={() => clickImage(i)}><Icon type={icon} /></span>
								
							<span 
								className={`icon main pdf topRight aniHover ${pdf ? 'selected' : ''}`} 
								style={{width: style.icon.width, height: style.icon.width}}
								onClick={() => addImage(image)}>
								{!pdf &&
									<Icon type="pdf" />
								}
								{pdf &&
									<Icon type="pdfActive" />
								}
								{pdf &&
									<Icon type="pdfRemove" />
								}
							</span>
						</div>
					)
					
				});

				return (<div className="grid" style={{height: `${Math.max(...colHeights)}px`}}>
					{content}
				</div>)

			}
		},
		[component.current, style.scale, cols, pdfImages]
	);
	
	//console.log('render Multigallery')
	//console.log(component.current, viewType)

	let navigation = [
		{label: language === 'de' ? 'Alle Bilder' : 'All Visuals', value: 'grid'},
		{label: language === 'de' ? 'Projekte' : 'Projects', value: 'teaser'}
	]

	if(data.startView==='teaser') navigation = navigation.reverse();

	return (
		<div className="content multiGallery clear">
			<div className="center main">
				<div ref={component}>
					{!view &&
						<div className="navigation main sub">
							<ul>
								{navigation.map((item, i) => (
									<li key={i}><a onClick={() => setViewType(item.value)} className={viewType===item.value ? 'selected' : null}><span>{item.label}<span /></span></a></li>
								))}
							</ul>
						</div>
					}
					{viewType==='grid' && 
						gridView
					}
					{viewType==='teaser' && 
						teaserView
					}
				</div>
			</div>
		</div>
	)
	
}

const mapStateToProps = (state) => {
	//console.log(state)
	const { galleryImages, language } = state.content
	const { pdfImages } = state.pdf
	
	return {
		galleryImages,
		language,
		pdfImages
	}
}

const mapDispatchToProps = dispatch => ({
	setGallery: images => dispatch(contentActions.setGallery(images)),
	addRemoveImage: image => dispatch(pdfActions.addRemoveImage(image)),
})

/*
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MultiGallery)
*/

function propsAreEqual(prev, next) {
	if(prev.pdfImages !== next.pdfImages) {
		return false
	}

	if(prev.style.scale === next.style.scale) {
		return true
	}
}

const enhance = compose(
    withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(React.memo(MultiGallery, propsAreEqual));
//export default React.memo(enhance(MultiGallery), propsAreEqual);