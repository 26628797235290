import axios from 'axios'
import { contentConstants } from '../constants';

function getConfig() {
    const request = () => { return { type: contentConstants.GETCONFIG_REQUEST } }
    const success = (data) => { 
        return { 
            type: contentConstants.GETCONFIG_SUCCESS, 
            data
        } 
    }
    const failure = (error) => { return { type: contentConstants.GETCONFIG_FAILURE, error } }

    return (dispatch, getState) => {
        dispatch(request());

        axios.get(process.env.REACT_APP_PUBLIC_API_URL + '/?config', {}, {headers: {}})
        .then(response => {      
            
            let data = response.data

            dispatch(success(data));
        })
        .catch(function (error) {
            //console.log(error)
        });

    } 
}

function getPage(path) {
    const request = () => { return { type: contentConstants.GETPAGE_REQUEST } }
    const success = (page) => { 
        return { 
            type: contentConstants.GETPAGE_SUCCESS, 
            page
        } 
    }
    const failure = (error) => { return { type: contentConstants.GETPAGE_FAILURE, error } }

    return (dispatch, getState) => {
        dispatch(request());

        let data = {
            params: {
                url: path
            }
        }

        axios.get(process.env.REACT_APP_PUBLIC_API_URL + '/index.php?url=' + path, {}, {headers: {}})
        .then(response => {      
            
            let page = response.data
            page.path = path

            dispatch(success(page));
        })
        .catch(function (error) {
            //console.log(error)
        });

    } 
}

function resize() {
    return (dispatch) => {
        dispatch( { type: contentConstants.RESIZE } )
    } 
}

function setGallery(images) {
    return (dispatch) => {
        dispatch({ 
            type: contentConstants.SET_GALLERY,
            images 
        })
    } 
}

function setLanguage(language) {
    return (dispatch) => {
        dispatch({ 
            type: contentConstants.SET_LANGUAGE,
            language 
        })
    } 
}

function setCookies(cookies) {
    return (dispatch) => {
        dispatch({ 
            type: contentConstants.SET_COOKIES,
            cookies 
        })
    } 
}

export const contentActions = {
    getConfig,
    getPage,
    resize,
    setGallery,
    setLanguage,
    setCookies
}