import React, { useRef } from 'react'
import { Link } from 'react-router-dom'

import Icon from 'components/icon/Icon'

import { ScaleSize } from 'Functions'

import { useIsVisivible } from 'hooks'

export default function Project({data, style, onClick, index}) {
	const ref = useRef()
	const { loaded, visible } = useIsVisivible({ref})

    let scale = style.scale
	style = {...style,
		h2: {
			fontSize: ScaleSize(28, 15, scale),
			lineHeight: ScaleSize(36, 19, scale)
        },
        icon: {
            width: ScaleSize(53, 28, scale),
        }
	}

	const classes = []
	visible && classes.push('show')
	
	return (
        <div
            className={'project fadeIn fromBottom ' + classes.join(' ')} style={{backgroundColor: data.color}}
            onClick={onClick ? () => onClick() : null}
            ref={ref} 
        >
            {!onClick &&
			    <Link to={data.url} className="area"></Link>
            }
            <div>
                <div className="image">
                    <div style={{backgroundImage: `url(${data.image})`}}>
                        {data.video &&
                         <video muted playsInline autoPlay loop>
                            <source src={data.video} type="video/mp4" />
                        </video>
                        }
                    </div>
                </div>
                <div className="text">
                    <div>
                        {data.logo &&
                            <img className="logo" src={data.logo} />
                        }
                        {data.text &&
                            <h2 style={style.h2} dangerouslySetInnerHTML={{__html: data.text}}></h2>
                        }
                    </div>
                    <span className="icon main" style={{width: style.icon.width, height: style.icon.width}}>
                        <Icon type="arrow" />
                    </span> 
                </div>
            </div>
		</div>
	)
}