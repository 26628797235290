import React from 'react'

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { pdfActions } from 'actions'
import Images from './Images'
import Icon from './../../icon/Icon'

import axios from 'axios'

import { ScaleSize } from '../../../Functions'

class PdfForm extends React.Component {
	constructor(props) {
		super(props);
		//this.stores = [ContentStore]
		this.state = {	...props, email: '', email2: '', title: '', text: '', privacy: false, errors: [] };

	}

	handleInputChange = (event) => {
        let name    = event.target.name;
        let value   = event.target.value;
        this.setState({[name]: value});
    }

    handlePrivacy = () => {
    	this.setState({
      		privacy: !this.state.privacy
    	});
  	}

    sendForm = () => {

    	let errors = []

    	if(!this.state.email) {
    		errors.push('email')
    	} 

    	if(!this.state.privacy) {
    		errors.push('privacy')
    	}

    	this.setState({errors: errors});

    	if(!errors.length) {

    		let data = {
    			email: this.state.email.trim(),
    			email2: this.state.email2.trim(),
    			title: this.state.title.trim(),
    			text: this.state.text.trim(),
    			language: this.props.language
    		}

    		this.setState({error: false, send: true});
			
			/* */

			let images = this.props.pdfImages.map(image => { 
				return image.ID
			});
	
			var formData = new FormData();
			formData.append('images', JSON.stringify(images));
			formData.append('email', data.email);
			formData.append('email2', data.email2);
			formData.append('title', data.title);
			formData.append('text', data.text);
			formData.append('language', data.language);
	
			axios.post('https://www.747studios.de/wordpress/wp-content/themes/747/php/pdf.php', formData)
			.then(response => {            
				/*
				this.setState({
					content
				})
				*/
			})
			.catch(function (error) {
				console.log(error)
			});

			/* */




    		//PdfStoreActions.sendMail(data);
    		this.props.updateAllImages([]);

    		if(this.props.language==='de') {
    			//this.props.setHeaderText('Das PDF wurde erfolgreich an Ihre E-Mail Adresse verschickt!<br />Vielen Dank!');
    		} else {
    			//this.props.setHeaderText('The PDF has been sent successfully to your e-mail address.<br />Many thanks!');
			}
			
			window.scrollTo(0, 0)
    	}

    }

	render() {

		let data = this.props.data,
		scale = this.props.style.scale

		let style = {...this.props.style,
			p: {
				fontSize: ScaleSize(34, 18, scale),
				lineHeight: ScaleSize(49, 26, scale)
			}
		}
				
		return (
			<div className="content pdfForm">
				<div className="center main">
					{this.state.send &&
						<div className="inner">	
							<div className="text">
								{this.props.language==='de' ? (
									<p style={style.p}>Das PDF wurde erfolgreich an Ihre E-Mail Adresse verschickt!<br />Vielen Dank!</p>
								):(
									<p style={style.p}>The PDF has been sent successfully to your e-mail address.<br />Many thanks!</p>
								)}
							</div>
						</div>
					}
					{!this.state.send &&
						<div className="inner">					
							<Images scale={this.props.scale} />			
							<div className="text">
								{this.props.language==='de' ? (
			                        <p style={style.p}>Das PDF wird an Ihre angegebene Adresse verschickt.<br />
	Bei Bedarf kann noch eine zusätzliche Adresse angegeben werden.</p>
			                    ):(
			                        <p style={style.p}>The PDF will be sent to your given address.<br />
			                        If required, an additional recipient can be specified.</p>
			                    )}
								
							</div>
							<div className="form">
								<form>
									<div>
										<label>{this.props.language==='de' ? 'IHRE E-MAIL ADRESSE (PFLICHTFELD)' : 'YOUR EMAIL ADDRESS (MANDATORY)'}</label>
										{this.state.errors.indexOf("email") === -1 &&
											<input 
				                                type="text"
				                                name="email"
				                                value={this.state.email}
				                                onChange={this.handleInputChange}
				                                placeholder="E-Mail" 
				                            />
			                        	}
			                        	{this.state.errors.indexOf("email") !== -1 &&
											<input 
				                                type="text"
				                                name="email"
				                                value={this.state.email}
				                                onChange={this.handleInputChange}
				                                placeholder="Bitte tragen Sie eine gültige E-Mail Adresse ein!" 
				                            />
			                        	}
			                            <label>{this.props.language==='de' ? 'WEITERER EMPFÄNGER (OPTIONAL)' : 'ADDITIONAL RECIPIENT (OPTIONAL)'}</label>
			                            <input 
			                                type="text"
			                                name="email2"
			                                value={this.state.email2}
			                                onChange={this.handleInputChange}
			                                placeholder="E-Mail" 
			                            />
			                            <label>{this.props.language==='de' ? 'Individuelles Titelblatt (optional)' : 'CUSTOM TITLE PAGE (OPTIONAL)'}</label>
			                            <input 
			                                type="text"
			                                name="title"
			                                value={this.state.title}
			                                onChange={this.handleInputChange}
			                                placeholder="Headline" 
			                            />
			                            <textarea name="text" value={this.state.text} onChange={this.handleInputChange} placeholder="Text" />
									</div>
									<div className={'privacy' + (this.state.errors.indexOf("privacy") !== -1 ? ' error' : '')}>
										{this.props.language==='de' ? (
					                        <label><input type="checkbox"  checked={this.state.privacy} onChange={this.handlePrivacy} />Für die Versendung des pdf’s ist die Speicherung Ihrer eingegebenen Daten erforderlich. Wir werden Sie über das PDF hinaus nicht ungefragt kontaktieren. Näheres zur Verarbeitung der E-Mail-Adresse können Sie auch unserer <Link to="/datenschutz">Datenschutzerklärung</Link> entnehmen.</label>
					                    ):(
					                       <label><input type="checkbox"  checked={this.state.privacy} onChange={this.handlePrivacy} />For sending the PDF, it is required to save your entered data. We will not contact you unsolicited beyond this PDF. Further details on the processing of the email address may be found in our <Link to="/en/data-protection">privacy policy</Link>.</label>
					                    )}
									</div>
									<div className="submit">
										<span className="icon main" onClick={this.sendForm}>
											<Icon type="arrow" />
										</span>
									</div>
								</form>
							</div>
						</div>
					}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state, props) => {
	
	const { language } = state.content
	const { pdfImages } = state.pdf

	return {
		language,
		pdfImages
	}
}

const mapDispatchToProps = dispatch => ({
	updateAllImages: images => dispatch(pdfActions.updateAllImages(images)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PdfForm)
