import React, { useState, useEffect } from 'react'

function LazyImage({top, url, alt, video}) {
	const [ visible, setVisible ] = useState(false)
	const [ loaded, setLoaded ] = useState(false)
	
	useEffect(() => {
		if(!loaded) {
			window.addEventListener('scroll', checkInView)  
			checkInView()
		}

		return () => {
			window.removeEventListener('scroll', checkInView)
		}
		
	}, [top])
	
	const checkInView = () => {
		const scrollTop = window.scrollY || document.documentElement.scrollTop
		const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
		
		if(scrollTop+height>top) { 
            setVisible(true)
			
			var i = new Image();
			i.onload = () => {
				setLoaded(true)
			};
			i.src = url;
			
			window.removeEventListener('scroll', checkInView)
        }
	}

	if(!visible) return null

	return <>
		<img className={'lazyImage' + (loaded ? ' loaded' : '')} src={url} alt={alt} />
		{video &&
             <video muted playsInline autoPlay loop>
                <source src={video} type="video/mp4" />
            </video>
        }
	</>
}

export default LazyImage