import React from 'react'
import styles from './Loading.module.sass'

function Loading({size, language}) {	
	let classNames = [styles.loading]
	size && classNames.push(size)

	return (
		<div className={classNames.join(' ')}>
			<span />
			<span />
			<span />
			<span />
			<span />
			<span />
			<span />
			<span />
			<span />
			<span />
			<span />
			<span />
			<span />
			<span />
			<p>{language === 'de' ? 'Laden' : 'Loading'}</p>
		</div>
	)
}

export default Loading;