import React, { useState, useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumb from 'components/content/Breadcrumb'
import Loading from 'components/loading'
import Icon from 'components/icon/Icon'

import { ScaleSize } from 'Functions'

import Player from '@vimeo/player';
import styles from './Header.module.sass'

import LazyImageContainer from 'components/LazyImageContainer'
import CookieMediaBlocker from 'components/CookieMediaBlocker'

function HeaderBig({ data, style, language, cookies }) {
	const [visible, setVisible] = useState(false)
	const [currentImage, setCurrentImage] = useState(0)
	const [muted, setMuted] = useState(true)

	const breadcrumb = data.breadcrumb;
	data = data.header

	useEffect(() => {
		setVisible(true)
	}, [])

	const media = useMemo(
		() => {
			if (data.contentType === 'video') {
				//return null

				return (
					<li>
						{cookies.externalMedia ? (
							<>
								<iframe
									id="iframe-vimeo"
									src={data.video + '?autoplay=1&controls=0&muted=1'}
									width="101%"
									height="101%"
									frameBorder="0"
									allow="autoplay; fullscreen">
								</iframe>
								<Loading language={language} />
							</>
						) : (
							<CookieMediaBlocker />
						)}
					</li>
				)
			} else {
				const lastImage = currentImage > 0 ? currentImage-- : data.images.length - 1
				const media = data.images.map((data, i) => {

					let style;

					if (i === currentImage) {
						style = { opacity: 1, zIndex: 3 }
					} else if (i === lastImage) {
						style = { opacity: 0, zIndex: 2 }
					}

					return <li key={i} style={{ ...style, backgroundColor: data.color }}><LazyImageContainer url={data.url} shouldLoad withFadeIn video={data.video} /></li>
				});

				return media
			}
		},
		[data, cookies],
	);

	const toggleMute = () => {
		var options = {
			id: 375699574,
			background: true
		};

		var player = new Player("iframe-vimeo", options);

		if (muted) {
			player.setVolume(1)
		} else {
			player.setVolume(0)
		}

		setMuted(!muted)
	}

	const h1DefaultSize = data.titleOptions && data.titleOptions.fontsize ? data.titleOptions.fontsize : 70
	const pDefaultSize = data.textOptions && data.textOptions.fontsize ? data.textOptions.fontsize : 34

	let scale = style.scale
	style = {
		...style,
		h1: {
			fontSize: ScaleSize(h1DefaultSize, 21, scale),
			lineHeight: ScaleSize(h1DefaultSize * 1.314, 42, scale)
		},
		h1Small: {
			fontSize: ScaleSize(56, 21, scale),
			lineHeight: ScaleSize(73, 42, scale)
		},
		p: {
			fontSize: ScaleSize(pDefaultSize, 18, scale),
			lineHeight: ScaleSize(pDefaultSize * 1.22, 22, scale)
		},
		iconBig: {
			width: ScaleSize(106, 43, scale),
		}
	}

	let classNames = [styles.header, data.type, 'fadeIn']
	visible && classNames.push('show')
	data.hideBreadcrumb && classNames.push('hideBreadcrumb')

	let styleHeader = {}

	if (data.type === 'big') {

		if (data.contentType === 'video') {
			styleHeader = { paddingBottom: `${data.video_height / data.video_width * 100}%` }

			if (data.titleOptions) {
				style.h1.color = data.titleOptions.color;

				if (data.titleOptions.onVideo[0] === 'true') {
					style.h1.marginTop = `-${style.h1.lineHeight}`;
				}

				if (data.titleOptions.fontFamily === 'exo2') {
					style.h1.fontFamily = "'Exo 2', sans-serif";
				}
			}

			if (data.textOptions) {
				style.p.color = 'data.textOptions.color';

				if (data.textOptions.fontFamily === 'gothamRounded') {
					style.p.fontFamily = '"Gotham Rounded A", "Gotham Rounded B"';
				}
			}


		} else {

			const image = data.images[currentImage]
			styleHeader = { paddingBottom: `${image['height'] / image['width'] * 100}%` }
		}
	}

	//data.title = 'Test';

	return (
		<div className={classNames.join(' ')}>
			<div style={{ background: data.color }}>
				<div>
					<div className="center main">
						<div>
							<div className={'media ' + data.contentType} style={styleHeader}>
								<ul>
									{media}
								</ul>
								{data.target &&
									<Link to={data.target} className="area">
										<span className="icon main selected middle" style={{ width: style.iconBig.width, height: style.iconBig.width }}>
											<Icon type="arrow" />
										</span>
									</Link>
								}
								{data.contentType === 'video' &&
									<span className="mute" style={{ width: style.iconSound.width, height: style.iconSound.width }} onClick={toggleMute}>
										<Icon type={muted ? 'soundon' : 'soundoff'} />
									</span>
								}
								{data.type === 'standard' &&
									<h1 className={'textfadeIn fromBottom ' + (data.target ? 'target' : '')} style={{ marginTop: 92 * scale > 42 ? `-${92 * scale}px` : '-42px', ...style.h1 }}>
										<span dangerouslySetInnerHTML={{ __html: data.title }} />
									</h1>
								}
							</div>
						</div>
					</div>
				</div>
				{data.type === 'big' &&
					<div className="text">
						<div className="center main">
							<div>
								{data.title &&
									<h1 className={'textfadeIn fromBottom ' + (data.target ? 'target' : '')} style={{ ...style.h1 }}>
										<span dangerouslySetInnerHTML={{ __html: data.title }} />
									</h1>
								}
								<div className="quote textfadeIn fromBottom delay" style={style.p}>
									<p dangerouslySetInnerHTML={{ __html: data.text }} />
									<span style={{ width: style.quote.width, height: style.quote.width }}>
									<svg width="30" height="29" viewBox="0 0 30 29">
											<g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" transform="translate(1)">
												<path d="M17.3137085,2.3137085 L17.3137085,31.5269119" transform="rotate(-135 17.314 17.314)" />
												<path d="M11.3137085,-3.6862915 L11.3137085,25.5269119" transform="rotate(-135 11.314 11.314)" />
											</g>
										</svg>
									</span>
									<span style={{ width: style.quote.width, height: style.quote.width }}>
										<svg width="30" height="29" viewBox="0 0 30 29">
											<g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" transform="translate(1)">
												<path d="M17.3137085,2.3137085 L17.3137085,31.5269119" transform="rotate(-135 17.314 17.314)" />
												<path d="M11.3137085,-3.6862915 L11.3137085,25.5269119" transform="rotate(-135 11.314 11.314)" />
											</g>
										</svg>
									</span>
								</div>
							</div>
						</div>
					</div>
				}
			</div>
			{!data.hideBreadcrumb &&
				<Breadcrumb data={breadcrumb} />
			}
		</div>
	)
}

export default HeaderBig;