import { useLocation } from 'react-router-dom';

const ParamAdd = (location, param, replace) => {
	var url = location.pathname;

    if(location.search && !replace) {
        url += location.search + '&' + param;  
    } else {
        url += '?' + param;
    }

    
	return url;
}

const ParamBack = (location, count=1) => {
	var search = location.search;

    //console.log('>>' + count);

    for(var i=0; i<count; i++) {

        if(search.lastIndexOf('&')!==-1) {
            search = search.substr(0, search.lastIndexOf('&'));
        } else {
            search = search.substr(0, search.lastIndexOf('?'));
        }

    }

    return(location.pathname + search);
}

const ScaleSize = (size, min, scale, unit) => {
    unit = unit ? unit : 'px'
    return size*scale > min ? `${size*scale}${unit}` : `${min}${unit}`
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const RichTextClick = (e, location, history) => {
    //console.log('click')

    if(e.target.tagName === 'A') {
        

        //console.log();
        
        let url = e.target.getAttribute('href').replace(/^.*\/\/[^\/]+/, '')
        let href = e.target.getAttribute('href')

       //console.log(href.split('/').pop().indexOf('.'))

        

        if(href.indexOf('@')>-1 || href.split('/').pop().indexOf('.') > -1) {

            //console.log('special')

        } else if(href.indexOf('747studios.de/')!==-1 ) {

            e.preventDefault()

            if(e.target.getAttribute('rel')==='overlay') {
                history.push({...location, search: `?page=${url}` })
            } else if(e.target.getAttribute('target')==='_blank') {
                window.open(href)
            } else {
                history.push(url)
            };
               
        } else {

            e.preventDefault()

            window.open(href)
        }
                    
    }
    //console.log(e.target)
}

const setCookie = (cname, cvalue, mins) => {
    var d = new Date();
    d.setTime(d.getTime() + (mins*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export { 
	ParamAdd,
  	ParamBack,
    ScaleSize,
    useQuery,
    RichTextClick,
    setCookie,
    getCookie
}