import React, { Component } from 'react'
import { ScaleSize } from 'Functions'
import Teaser from './Teaser'
import Project from './Project'
import styles from './Teaser.module.sass'

class TeaserList extends Component {
	render() {
		let data = this.props.data,
		scale = this.props.style.scale

		let style = {...this.props.style,
			p: {
				fontSize: ScaleSize(28, 18, scale),
				lineHeight: ScaleSize(36, 26, scale)
			}
		}

		//console.log(data.teaserType)

		const content = data.content.map((entry, i) => {
			//console.log(data.teaserType)
			if(data.teaserType==='project') {
				return <Project key={i} data={entry} style={style} index={i} />
			} else {
				return <Teaser key={i} data={entry} style={style} index={i} />
			}
			
		})
		
		return (
			<div className="content">
				<div className="center main">
					<div className={styles.teaser}>
						{content}
					</div>
				</div>
			</div>
		)
	}
}

export default TeaserList;