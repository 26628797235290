import React from 'react';
import styles from './NextProject.module.sass'
import { withRouter, Link } from 'react-router-dom'
import Icon from 'components/icon/Icon'
import { ScaleSize } from 'Functions'

const index = ({style, data, location, language}) => {

    //const back = location.pathname.substring(0, location.pathname.lastIndexOf('/'));

    var path = location.pathname.split("/");
    var back = path.slice(0, path.length-2).join("/");

    //console.log(style)

    style = {...style,
        h2: {
            fontSize: ScaleSize(30, 18, style.scale),
            lineHeight: ScaleSize(36, 22, style.scale)
        },
        back: {
            lineHeight: ScaleSize(53, 43, style.scale)
        }
    }

    const styleTable = data.color ? {background: data.color} : {}

    return (
        <div className={styles.next}>
            <div className="title">
                <div className="center main">
                    <div className="inner">
                        <h4 style={style.h2}>{language === 'de' 
                            ? 'Dieses Projekt könnte sie auch interessieren'
                            : 'This project could also interests you'
                        }</h4>
                    </div>
                </div>
            </div>
            <div className="project">
                <div className="center main">
                    <div className="inner">
                        <Link to={data.url} className="area" />
                        <div className="table" style={styleTable}>
                            <div className="col image">
                                <div style={{backgroundImage: `url(${data.image})`}} />
                            </div>
                            <div className="col text">
                                {data.logo && <img className="logo" src={data.logo} /> }
                                <h5 style={style.h2}><span dangerouslySetInnerHTML={{__html: data.title}}></span></h5>
                            <span className="icon main" style={{width: style.icon.width, height: style.icon.width}}>
                                <Icon type="arrow" />
                            </span>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="back">
                <div className="center main">
                    <div>
                        <Link to={back} style={style.back}>
                            <span className="icon main" style={{width: style.icon.width, height: style.icon.width}}>
                                <Icon type="arrow" />
                            </span>
                            {language === 'de' 
                                ? 'Zur Übersicht'
                                : 'To overview'
                            } 
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(index);