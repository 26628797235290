import { combineReducers } from 'redux'
import { content } from './content';
import { pdf } from './pdf';

const rootReducer = combineReducers({
    content,
    pdf
})

export default rootReducer
