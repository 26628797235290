import React, { useRef } from 'react';
import styles from './TextImage.module.sass'
import { withRouter } from 'react-router-dom'
import { RichTextClick } from 'Functions'
import { useIsVisivible } from 'hooks'

const TextImage2 = ({ style, data, location, history }) => {
    const ref = useRef()
	const { visible } = useIsVisivible({ref})

    let content
    /*
    
    data.text = '<p>Testi Test<br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>ss</p>'
    data.image = 'https://www.747studios.de/wordpress_v2/wp-content/uploads/2019/02/09_747_CGI_Kueche_F22OT_Keyvisual_Mann_RGB-1024x576.jpg';
    data.width = 1024
    data.height = 768
    data.color = '#DDDDDD'
    //data.typeColor = 'overlap'
    data.direction = 'reverse'
    */

    let classes = []
    let col1Classes = []
    let col2Classes = []

    if(data.direction) {
        classes.push('reverse')
        col1Classes.push('delay');
    } else {
        col2Classes.push('delay');
    }

    if(visible) {
        col1Classes.push('show')
        col2Classes.push('show')
    }

    let styleImageCol = {}
    let styleImage = {paddingBottom: `${data['height'] / data['width'] * 100}%` }

   

    if (data.typeColor === 'overlap') {
        classes.push('overlap')
        styleImage = {...styleImage, backgroundImage: `url(${data.url})`}
    } else {
        styleImageCol = {backgroundImage: `url(${data.url})`}
    }

    content = <div className={'test ' + classes.join(' ')}>
        <div className={'col image fadeIn fromBottom ' + col1Classes.join(' ')} style={styleImageCol}>
            <div style={styleImage}></div>
        </div>
        <div className={'col text fadeIn fromBottom ' + col2Classes.join(' ')}>
            <span className="bg" style={{ backgroundColor: data.color }} />
            <div className="rich" style={style.rich} dangerouslySetInnerHTML={{ __html: data.text }} />
        </div>
    </div>

    

    return (
        <div className={'content ' + styles.text} ref={ref}>
            <div className="center main">
                <div className="inner" onClick={(e) => RichTextClick(e, location, history)}>
                    {data.title &&
                         <h2 style={style.h2}>{data.title}</h2>
                    }
                    {content}
                </div>
            </div>
        </div>
    );
};

export default withRouter(TextImage2);