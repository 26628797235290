import React from 'react';
import styles from './Checkbox.module.sass'

const IconCheckbox = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
            <path d="M16,0H2A2,2,0,0,0,0,2V16a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2ZM7,14,2,9.19,3.4,7.85,7,11.31,14.6,4,16,5.35,7,14Z" fill="#E8247E" fillRule="evenodd" />
            <path d="M16,2V16H2V2Zm0-2H2A2,2,0,0,0,0,2V16a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2Z" fill="#E8247E" fillOpacity="0.7" fillRule="evenodd" />
        </svg>
    );
};

const Checkbox = ({children, onSelect, selected, className=''}) => {
    
    const classNames = [styles.root]
    if(selected) classNames.push('active')
    if(className) classNames.push(className)
    
    return (
        <span className={classNames.join(' ')} onClick={() => onSelect(!selected)}>
             <IconCheckbox />
             {children}
        </span>
    );
};

export default Checkbox;