import React, { useRef } from 'react';
import styles from './Text2.module.sass'
import { withRouter } from 'react-router-dom'
import {  RichTextClick } from 'Functions'
import { useIsVisivible } from 'hooks'

const Text2 = ({ style, data, location, history }) => {
    const ref = useRef()
	const { visible } = useIsVisivible({ref})

    let content

    let styleMain = {}
    let styleInner = {}

    if (data.typeColor === 'all' && !data.fullWidthColor) {
        styleInner = { backgroundColor: data.color }
    }

    if (data.typeColor === 'all' && data.fullWidthColor) {
        styleMain = { backgroundColor: data.color }
    }

    if (data.cols === 2) {

        let classContainer = ''

        if (data.typeColor === '2colsOverlap') {
            classContainer = 'overlap'
        }

        content = <div className={'flex ' + classContainer}>
            <div className={'col fadeIn fromBottom' + ((data.typeColor == '2colsOverlap' && data.colorOverlap === 1) ? ' overlap' : '') + (visible ? ' show' : '')}>
                {(data.typeColor == '2cols' || data.typeColor == '2colsOverlap') &&
                    <span className={'bg'} style={{ backgroundColor: data.color_1 }} />
                }
                <div className="rich"  style={style.rich} dangerouslySetInnerHTML={{ __html: data.text_1 }} />
            </div>
            <div className={'col fadeIn fromBottom delay' + ((data.typeColor == '2colsOverlap' && data.colorOverlap === 2) ? ' overlap' : '') + (visible ? ' show' : '')}>
                {(data.typeColor == '2cols' || data.typeColor == '2colsOverlap') &&
                    <span className={'bg'} style={{ backgroundColor: data.color_2 }} />
                }
                <div className="rich"  style={style.rich} dangerouslySetInnerHTML={{ __html: data.text_2 }} />
            </div>
        </div>

    } else if (data.cols === 1) {

        content = <div className={'test cols1 fadeIn fromBottom ' + (visible ? ' show' : '') }>
            <div className="rich" style={style.rich} dangerouslySetInnerHTML={{ __html: data.text }} />
        </div>
    }

    return (
        <div className={'content ' + styles.text} style={styleMain} ref={ref}>
            <div className="center main">
                <div className="inner" style={styleInner} onClick={(e) => RichTextClick(e, location, history)}>
                    {data.title &&
                         <h2 style={style.h2}>{data.title}</h2>
                    }
                    {content}
                </div>
            </div>
        </div>
    );
};

export default withRouter(Text2);