import React from 'react';
import styled, { css } from 'styled-components'
import styles from './Contact.module.sass'
import { withRouter } from 'react-router-dom'
import Icon from 'components/icon/Icon'
import { RichTextClick, ScaleSize } from 'Functions'

const StyledContactTeaser = styled.div`
    ${({ data }) => data.color!==undefined && css`
        .test {
			background-color: ${data.color} !important;
		}
    `}

    ${({ data }) => data.fontcolor!==undefined && css`
        p {
			color: ${data.fontcolor} !important;
		}

        .icon.main {
            border-color: ${data.fontcolor} !important;
        }

        svg {
            stroke: ${data.fontcolor} !important;
        }
    `}

    ${({ data }) => data.colorMouseover!==undefined && css`
        .inner  > span {
			background-color: ${data.colorMouseover} !important;
		}
    `}

    ${({ data }) => data.fontcolorMouseover!==undefined && css`
        .test:hover {
            p {
			    color: ${data.fontcolorMouseover} !important;
            }

            .icon.main {
                border-color: ${data.fontcolorMouseover} !important;
            }

            svg {
                stroke: ${data.fontcolorMouseover} !important;
            }
        }
    `}
`

const contactTeaser = ({style, location, history, data}) => {

    let scale = style.scale

    style = {...style,
		p: {
			fontSize: ScaleSize(30, 18, scale),
			lineHeight: ScaleSize(36, 26, scale)
		}
    }
    
    //const attribute = data.newTab ? "target='_blank'" : null

    return (
        <StyledContactTeaser 
            className={'content ' + styles.contact}
            data={data}
            > 
            <div className="test">
                <a className="area" href={`${data.target}`}  onClick={(e) => RichTextClick(e, location, history)} target={data.newTab ? '_blank': null} />      
                <div className="center main">
                    <div className="inner">
                        <p style={style.p}>{data.text}
                            <span className="icon main" style={{width: style.icon.width, height: style.icon.width}}>
                                <Icon type="arrow" />
                            </span>
                        </p>
                        <span />
                    </div>
                </div>          
            </div>
        </StyledContactTeaser>
    );
};

export default withRouter(contactTeaser);