import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import Breadcrumb from '../Breadcrumb'
import { contentActions } from 'actions'
import { ScaleSize } from 'Functions'

import LazyImageContainer from 'components/LazyImageContainer'

import styles from './Header.module.sass'

function HeaderProject({data, style, setGallery, history, location, language}) {
	let dataBreadcrumb = data.breadcrumb
	
	data = data.header
	let scale = style.scale

	style = {
		...style, 
		h1: {
			fontSize: ScaleSize(34, 21, scale),
			lineHeight: ScaleSize(43, 27, scale),
		}
	}

	const clickImage = () => {
		//console.log(number);

	   	setGallery(data.gallery)

		let image = data.gallery[0]

		//this.context.router.history.push( ParamAdd(this.props.location, 'gallery=' + image.ID))
	
	   	history.push({...location, search: `?gallery=${image.ID}` })
   }

	let style2 = {fontSize: ScaleSize(1, 0.8, scale, 'em')}

		return (
			<div className={styles.header}>
				<div className="center main">
					<div className="inner clear">
						<div style={{background: data.color}} >
							<div className="image">
								<div>
									<div style={{backgroundColor: data.imageColor}}>
										<LazyImageContainer url={data.image} shouldLoad withFadeIn />
									</div>
								</div>
							</div>
							<div className="text">
								<div>
									{data.logo &&
										<span className="logo">
											<span  
												style={{
													paddingBottom: `${data.logoHeight / data.logoWidth * 100}%`,
													backgroundImage: `url(${data.logo})`
												}}
											/>
										</span>
									}
									<h1 style={style.h1} dangerouslySetInnerHTML={{__html: data.title}}></h1>
									<div dangerouslySetInnerHTML={{__html: data.text}} className="rich" style={style2} />							
								</div>
							</div>
						</div>
						{data.gallery &&
							<span className="gallery" onClick={() => clickImage()}>{language === 'de' ? 'Galerie & PDF erstellen' : 'Gallery & create PDF'}</span>
						}
					</div>
				</div>
				<Breadcrumb data={dataBreadcrumb} />
			</div>
		)
	
}

const mapStateToProps = (state) => {
	const { language } = state.content
	
	return {
		language
	}
}


const mapDispatchToProps = dispatch => ({
	setGallery: images => dispatch(contentActions.setGallery(images))
})

/*
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MultiGallery)
*/


const enhance = compose(
    withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(React.memo(HeaderProject));