import React, {useState, useRef, useEffect } from 'react'

import styles from './Logos.module.sass'
import { withRouter, Link } from 'react-router-dom'

function test() {
    const width = window.innerWidth || document.documentElement.clientWidtht || document.body.clientWidth
    const slider = width < 723 ? true : false

    return slider;
}

function Index({style, data}) {
    const [ isSlider, setIsSlider ] = useState(test())
    const [ slideNumber, setSlideNumber ] = useState(0)
    
    const resize = () => {
        const isSliderNew = test();
        setIsSlider(isSliderNew)
    }

    useEffect(() => {
        window.addEventListener('resize', resize)     

		return () => {
            window.removeEventListener('resize', resize)
        }
    }, [])
    
    useEffect(() => {
        let interval = null;
        
        if (isSlider) {
            interval = setInterval(() => {
                //console.log(slideNumber)
                
                setSlideNumber(slideNumber => {

                    let slideNumberNew = slideNumber + 1
                   
                    return slideNumberNew

                })
            }, 3000);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
		
    }, [isSlider])
    


    //console.log(style)

    const width = window.innerWidth || document.documentElement.clientWidtht || document.body.clientWidth
    const slider = width < 723 ? true : false

    const classes = [styles.logos]
    isSlider && classes.push('slider')
    
    let styleSlider = {}

    let left = useRef(0)

    if(isSlider) {

 
        let roundTest = (slideNumber+1)*3%data.content.length

        if(roundTest===0) {
            left.current = slideNumber

        }

        //console.log('slideNumber: ' + slideNumber)
        //console.log('min: ' + min)
        //console.log('Round: ' + roundTest)

        styleSlider = {
            transform: `translateX(-${slideNumber*100}%)`,
            left: `${left.current*100}%`,
        }
    }

    const logos = data.content.map((entry, i) => {
        return <div key={i}>
            <div style={{backgroundImage: `url(${entry.image})`}}>
            {entry.target &&
                <Link className="area" to={entry.target} />
            }
            </div>
        </div>
    })

    return (
        <div className={'content ' + classes.join(' ')} style={{backgroundColor: data.color}}>       
            <div className="center main">
                <div className="inner">
                    <h2 style={style.h2}>{data.title}</h2>
                    <div className="container">
                        <div className="slider" style={styleSlider}>
                            <div>
                                {logos}
                                {isSlider && logos}
                                {isSlider && logos}
                                {isSlider && logos}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Index);