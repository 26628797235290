import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { contentActions } from '../../actions'

import HeaderBig from '../content/header'
import HeaderProject from '../content/headerProject'

import TeaserList from '../content/teaserList'
import TextImage from '../content/TextImage'
import MultiGallery from '../content/MultiGallery'
import Gallery from '../content/Gallery'
import Text from '../content/Text'
import PdfForm from '../content/pdfForm'
import Location from '../content/Location'
import Team from '../content/Team'
import Images from '../content/images'
import Contact from '../content/contact'
import NextProject from '../content/nextProject'

import TeaserCategory from '../content/teaserCategory'
import TeaserCategoryV2 from '../content/teaserCategory_v2'
import Logos from '../content/logos'
import ContactTeaser from '../content/contactTeaser'
import Media from '../content/media'
import Text2 from '../content/text2'
import TextImage2 from '../content/textImage2'

import Loading from '../loading'
import { CSSTransition } from 'react-transition-group'


const components = {
    teaserList: TeaserList,
    textImage: TextImage,
    multiGallery: MultiGallery,
    gallery: Gallery,
    text: Text,
    pdfForm: PdfForm,
    location: Location,
	team: Team,
	images: Images,
	contact: Contact,
	nextProject: NextProject,
	teaserCategory: TeaserCategory,
	teaserCategory_v2: TeaserCategoryV2,
	logos: Logos,
	contactTeaser: ContactTeaser,
	media: Media,
	text2: Text2,
	textImage2: TextImage2
}

const setTitle = (data, match) => {
	
	console.log(match)

	

	let title;
	let description; 

	switch(match.params.level1) {
		case 'photography-film':
			title = 'Fotos & Film für Marken - 747 Studios | Produkt & Food | est.2002';
			description = 'Überzeugende Fotos ✓Digital ✓CGI ✓3D für Markenprodukte. Fotorealistisch. Mit ♥ Herz und Seele. Überzeugen? Hier►';
			break;

		case '3d-cgi':
			title = 'CGI - 747 Studios | Produkt & Food | est.2002';
			description = 'Überzeugende CGIs & Renderings für Markenprodukte. Fotorealistisch. Mit ♥ Herz und Seele. Überzeugen? Hier►';
			break;

		case 'interactive-experience':
			title = 'VR & AR - 747 Studios | Produkt & Food | est.2002';
			description = 'Virtuelle Experiences für Marken. Showrooms, Rundgänge. Fotorealistisch. Mit ♥ Herz und Seele. Überzeugen? Hier►';
			break;

		case 'ueber-uns':
			title = 'Über uns - 747 Studios |  est.2002';
			description = 'Unsere Produktionsstudios ist angetrieben vom Geist menschlicher Werte. Von Menschen mit ♥ und Seele. Mehr erfahren? Hier►';
			break;

		case 'jobs':
			title = 'Jobs - 747 Studios |  est.2002';
			description = 'Erfüllender Arbeitsplatz gesucht? Willkommen im 747 Team! Mehr erfahren? Hier►';
			break;

		case 'impressum':
			title = 'Impressum - 747 Studios |  est.2002';
			break;

		case 'datenschutz':
			title = 'Datenschutz - 747 Studios |  est.2002';
			break;

		case 'agb':
			title = 'AGB - 747 Studios |  est.2002';
			break;

		case 'en':
			switch(match.params.level2) {
				case 'photography-film':
					title = 'Photos for brands - 747 Studios | Product & Food | est.2002';
					description = 'Convincing photos ✓Digital ✓CGI ✓3D for brandproducts. Photorealistic. With ♥ heart and soul. Convince? Here►';
					break;

				case '3d-cgi':
					title = 'CGI - 747 Studios | Product & Food | est.2002';
					description = 'Compelling CGIs & renderings for brandproducts. Photorealistic. With ♥ heart and soul. Convince? Here►';
					break;

				case 'interactive-experience':
					title = 'VR & AR - 747 Studios | Product & Food | est.2002';
					description = 'Virtual Experiences for Brands. Showrooms, tours. Photorealistic. With ♥ heart and soul. Convince? Here►';
					break;

				case 'about-us':
					title = 'About us - 747 Studios | est.2002';
					description = 'Our production studios is driven by the spirit of human values. By people with ♥ and soul. Learn more? Here►';
					break;
				
				case 'jobs':
					title = 'Jobs - 747 Studios | est.2002';
					description = 'Fulfilling job wanted? Welcome to the 747 Team! Learn more? Here►';
					break;

				case 'imprint':
					title = 'Imprint - 747 Studios |  est.2002';
					break;

				case 'data-protection':
					title = '';
					break;
				
				case 't-c':
					title = 'Data Protection - 747 Studios |  est.2002';
					break;
				
				default:
					title = '747 Studios | photography, film, cgi |  for brands | est.2002';
					description = 'Your production studio for ✓Photo ✓Film ✓Interactive Experiences. With ♥ heart and soul. Convince? Here►';
					break;
			}

			if(match.params.level3) {
				title = data.title.replace(/&#8217;/g, "’").replace(/&#038;/g, "&").replace(/&#8211;/g, "–") + ' - 747 Studios | est.2002'
			}

			break;

		
		default:
			title = '747 Studios | Fotografie, Film, CGI |  für Marken | est.2002';
			description = 'Ihr Produktionsstudio für ✓Foto ✓Film ✓Intreractive Experiences. Mit ♥ Herz und Seele. Überzeugen? Hier►';
			break;	
	}

	if(match.params.level1 !== 'en' && match.params.level2) {
		title = data.title.replace(/&#8217;/g, "’").replace(/&#038;/g, "&").replace(/&#8211;/g, "–") + ' - 747 Studios | est.2002'
	}

	document.title = title;

	if(description) {
		document.querySelector('meta[name="description"]').setAttribute("content", description);
	}
}


function Page({location, data, getPage, style, language, cookies, match}) {
	const [ headerText, setHeaderText ] = useState(false)

	useEffect(() => {
		window.scrollTo(0, 0)

		if(!data) { 

			let path = location.pathname

			if(location.search) path+= location.search; 

			//console.log('load now')
			getPage(path)
		}	
	}, [])
	
	useEffect(() => {
		if(data) { 
			setTitle(data, match)
			
		}		
	}, [match, data])

	useEffect(() => {
		if(data) {
			//ReactGA.initialize('UA-46258608-1');
			//ReactGA.pageview(location.pathname + location.search);

			//console.log('track' + location.pathname + data.title)

			//console.log(window.dataLayer)
			
			/*
			window.dataLayer.push({
				event: 'pageview',
				page: {
				  url: location.pathname + location.search,
				  title: data.title
				}
			  });
			*/
		}


		
	}, [location, data])
	
	/*
 	updateData(props, state) {
 		if(this.url !== props.location.pathname) {
 			this.url = props.location.pathname
 			//ContentStoreActions.getContent(props.location.pathname)
 			window.scrollTo(0, 0)

 			if(window.location.hostname!=='localhost') {
				//ReactGA.initialize('UA-46258608-1');
				//ReactGA.pageview(props.location.pathname + props.location.search);
			}
 		}
 	}

	componentDidMount() {
		this.updateData(this.props, this.state)
 	}

 	componentWillUpdate(nextProps, nextState) {
 		this.updateData(nextProps, nextState)

 		let data = nextState.content[encodeURIComponent(this.props.location.pathname)]

 		if(data) {
			document.title = data.title.replace(/&#038;/g, "&") + ' - 747 Studios';
		}
	}
	*/


	//let data = this.props.content[encodeURIComponent(this.props.location.pathname)],
	//console.log(this.props.location.pathname)

	let header,
	content
	
	if(data) {
		if(data.header && (data.header.type === 'big' || data.header.type === 'standard')) {
			header = <HeaderBig data={data} style={style} headerText={headerText} language={language} cookies={cookies} />
		}
		
		if(data.header && (data.header.type === 'project')) {
			header = <HeaderProject data={data} style={style} />
		}
		
		content = data.content.map((data, idx) => { 

			const Content = components[data.type];
			return <Content key={idx} data={data} style={style} language={language} cookies={cookies} setHeaderText={
				(text) => { this.setState({headerText: text}) }
			} />;
		});
		
	}
	

	return (
		<div className="page">
			{!data &&
				<div className="center main">
					<div className="inner">
						<div className="loading test">
							<Loading language={language} />
						</div>
					</div>
				</div>
			}
			<CSSTransition
				in={!!data}
				classNames="fade2"
				appear={true}
				timeout={500}>
				<div>
					{header}
					{content}
				</div>
			</CSSTransition>	
		</div>
	)
}

const mapStateToProps = (state, props) => {
	const { pages, style, language, cookies } = state.content

	let path = props.location.pathname

	if(props.location.search) path+= props.location.search; 


	const data = pages.find(page => page.path === path) 

	return {
		data,
		style,
		language,
		cookies
	}
}

const mapDispatchToProps = dispatch => ({
	getPage: (url) => dispatch(contentActions.getPage(url)), 
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Page)