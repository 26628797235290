import React from 'react';
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import styles from './Cookie.module.sass'
import { getCookie, setCookie } from 'Functions';
import { useState } from 'react';
import { contentActions } from 'actions'
import Checkbox from 'components/Checkbox';

const CookieMediaBlock = ({ language, cookies, setCookies }) => {
    
    const playVideo = () => {
        setCookies(
            {
                ...cookies,
                externalMedia: true
            }
        )
    }
    
    return (
        <div className={styles.root}>
            <p>Mit dem Laden des Videos akzeptieren Sie die Datenschutzerklärung von Vimeo.
                <br /><br/>
            <span onClick={playVideo}>Video laden</span></p>
        </div>
    )
};

const mapStateToProps = (state, props) => {
    const { language, cookies } = state.content

    return {
        language,
        cookies
    }
}

const mapDispatchToProps = dispatch => ({
    setCookies: cookies => dispatch(contentActions.setCookies(cookies)),
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CookieMediaBlock))