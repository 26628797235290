import React, { useState, useRef, useEffect } from "react";
import { withRouter, NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import IconSocial from "components/icon/IconSocial";
import styles from "./Header.module.sass";
import Dropdown from "components/Dropdown";

const navigation = {
  en: {
    navigation1: [
      {
        path: "/en",
        text: "Home",
      },
      {
        path: "/en/photo-motion/",
        text: "Photo & Motion",
      },
      {
        path: "/en/3d-cgi",
        text: "3D/CGI",
      },
      {
        path: "/en/interactive-experience",
        text: 'Interactive<span class="small"> Experience</span>',
      },
    ],
    navigation2: [
      {
        path: "/en/about-us",
        text: "About us",
      },
      {
        path: "/en/studio",
        text: "Studio",
      },
    ],
  },
  de: {
    navigation1: [
      {
        path: "/",
        text: "Home",
      },
      {
        path: "photo-and-motion",
        text: "Photo & Motion",
      },
      {
        path: "/3d-cgi",
        text: "3D/CGI",
      },
      {
        path: "/interactive-experience",
        text: 'Interactive<span class="small"> Experience</span>',
      },
    ],
    navigation2: [
      {
        path: "/ueber-uns",
        text: "Über uns",
      },
      {
        path: "/studio",
        text: "Studio ",
      },
    ],
  },
};

function Header({ language, pdfImages, location, support, config, history }) {
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [visible, setVisible] = useState(false);

  const ref = useRef(null);
  let lastScroll = useRef(null);
  let skipAnimate = useRef(false);

  useEffect(() => {
    //console.log('header', ref.current)
    const scroll = () => {
      //console.log('scroll', skipAnimate, open)

      if (!open && !skipAnimate.current) {
        const scrollTop = window.scrollY;

        if (scrollTop > 200 && !scrolled) setScrolled(true);
        if (scrollTop === 0 && scrolled) setScrolled(false);

        if (scrollTop < lastScroll && scrolled) setVisible(true);
        if (scrollTop > lastScroll && visible) setVisible(false);

        lastScroll = scrollTop;

        //console.log(lastScroll)
      }
    };

    window.addEventListener("scroll", scroll);

    return () => {
      window.removeEventListener("scroll", scroll);
    };
  }, [scrolled, visible]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const gallery = parseInt(params.get("gallery"));

    if (gallery && !visible) {
      skipAnimate.current = true;
      //console.log('Set skip animate')
    } else if (skipAnimate && !gallery) {
      setTimeout(() => {
        skipAnimate.current = false;
        //console.log('delete skip animate')
      }, 510);
    }
  }, [location]);

  const isActive = (path) => {
    if (path === "/" || path === "/en") {
      return location.pathname === path;
    }

    if (location.pathname.indexOf(path) !== -1) {
      return true;
    }
  };

  const isActiveLanguage = (_language) => {
    if (language === _language) {
      return true;
    }
  };

  let btns1 = navigation[language].navigation1.map((entry, idx) => {
    return (
      <li key={idx}>
        <NavLink
          to={entry.path}
          activeClassName="selected"
          isActive={() => isActive(entry.path)}
          onClick={() => setOpen(false)}
        >
          <span>
            {" "}
            <span
              className="text"
              dangerouslySetInnerHTML={{ __html: entry.text }}
            />
            <span />{" "}
          </span>
        </NavLink>{" "}
      </li>
    );
  });

  let btns2 = navigation[language].navigation2.map((entry, idx) => {
    return (
      <li key={idx}>
        {" "}
        <NavLink
          to={entry.path}
          activeClassName="selected"
          isActive={() => isActive(entry.path)}
          onClick={() => setOpen(false)}
        >
          <span>
            {" "}
            <span
              className="text"
              dangerouslySetInnerHTML={{ __html: entry.text }}
            />
            <span />{" "}
          </span>
        </NavLink>{" "}
      </li>
    );
  });

  let marginTop = ref.current && open ? ref.current.clientHeight : 0;

  const classes = [];
  open && classes.push("show-navigation");
  scrolled && classes.push("scrolled");
  visible && classes.push("visible");

  !support.SUPPORTS_BACKDROP_FILTER && classes.push("fix-opacity");

  //console.log(pdfImages.length)

  return (
    <div id="header" className={styles.header}>
      <div className={classes.join(" ")}>
        <div className="center main">
          <div className="inner">
            <span className="menu" onClick={() => setOpen(!open)}>
              <span />
              <span />
              <span />
            </span>
            <div
              className="navigation main"
              id="navigation"
              style={{ height: marginTop + "px" }}
            >
              <div ref={ref}>
                <ul>{btns1}</ul>
                <ul>{btns2}</ul>
                <ul>
                  <li>
                    <NavLink
                      to="/"
                      activeClassName="selected"
                      isActive={() => isActiveLanguage("de")}
                      onClick={() => setOpen(false)}
                    >
                      <span>
                        DE<span></span>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    {" "}
                    <NavLink
                      to="/en"
                      activeClassName="selected"
                      isActive={() => isActiveLanguage("en")}
                      onClick={() => setOpen(false)}
                    >
                      <span>
                        EN<span></span>
                      </span>
                    </NavLink>
                  </li>
                </ul>
                <div className="navigation social">
                  <a href="https://www.behance.net/747Studios" target="blank">
                    <IconSocial type="Behance" color="ffffff" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/747studios"
                    target="blank"
                  >
                    <IconSocial type="linkedin" color="ffffff" />
                  </a>
                  <a
                    href="https://www.instagram.com/747studios/"
                    target="blank"
                  >
                    <IconSocial type="instagram" color="ffffff" />
                  </a>
                </div>
              </div>
            </div>
            <NavLink
              to={language === "de" ? "/" : "/en"}
              className="logo"
            ></NavLink>
            {config.headerSearchCategories && 1 == 2 && (
              <Dropdown
                label="Bildersuche"
                options={config.headerSearchCategories.map((item) => ({
                  value: item.ID,
                  name: item.name,
                }))}
                onSelect={(items) => {
                  const test = items.find((item) => item.selected === true);
                  console.log(test);

                  history.push({
                    ...location,
                    pathname: "suche&c=" + test.value,
                  });
                }}
              ></Dropdown>
            )}
          </div>
        </div>
      </div>
      {pdfImages.length > 0 && (
        <Link to={language === "de" ? "/pdf" : "/en/pdf"} className="pdf">
          PDF({pdfImages.length})
        </Link>
      )}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const { language, support, config } = state.content;
  const { pdfImages } = state.pdf;

  return {
    language,
    support,
    pdfImages,
    config,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
