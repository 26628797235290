import React from 'react'

const Icon = ({ type }) => {
    function getIcon() {

        switch (type) {
            case 'arrow':
                return (
                    <svg width="55" height="55" viewBox="2 2 51 51" stroke="#E8247E" className="stroke">
                        <g fill="none" fillRule="evenodd">
                            <polyline strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" points="28 23 28 33 38 33" transform="rotate(-135 33 28)" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M38,28 L15,28" />
                        </g>
                    </svg>

                )

            case 'pdf':
                return (
                    <svg width="55" height="55" viewBox="2 2 51 51" className="pdf">
                        <g fill="none" fillRule="evenodd">
                            <path className="fill" fill="#E8247E" d="M12.755,32.16 L12.755,23.43 C12.755,22.905 13.16,22.5 13.685,22.5 L16.895,22.5 C19.355,22.5 20.885,23.895 20.885,26.01 L20.885,26.04 C20.885,28.395 18.995,29.625 16.685,29.625 L14.6,29.625 L14.6,32.16 C14.6,32.67 14.195,33.075 13.685,33.075 C13.16,33.075 12.755,32.67 12.755,32.16 Z M14.6,27.96 L16.745,27.96 C18.14,27.96 19.01,27.18 19.01,26.085 L19.01,26.055 C19.01,24.825 18.125,24.18 16.745,24.18 L14.6,24.18 L14.6,27.96 Z M23.475,32.07 L23.475,23.43 C23.475,22.905 23.88,22.5 24.405,22.5 L27.39,22.5 C30.69,22.5 32.97,24.765 32.97,27.72 L32.97,27.75 C32.97,30.705 30.69,33 27.39,33 L24.405,33 C23.88,33 23.475,32.595 23.475,32.07 Z M25.32,31.32 L27.39,31.32 C29.595,31.32 31.035,29.835 31.035,27.78 L31.035,27.75 C31.035,25.695 29.595,24.18 27.39,24.18 L25.32,24.18 L25.32,31.32 Z M35.905,32.16 L35.905,23.43 C35.905,22.905 36.31,22.5 36.835,22.5 L42.955,22.5 C43.42,22.5 43.795,22.875 43.795,23.34 C43.795,23.805 43.42,24.18 42.955,24.18 L37.75,24.18 L37.75,27.045 L42.28,27.045 C42.745,27.045 43.12,27.42 43.12,27.885 C43.12,28.35 42.745,28.725 42.28,28.725 L37.75,28.725 L37.75,32.16 C37.75,32.67 37.345,33.075 36.835,33.075 C36.31,33.075 35.905,32.67 35.905,32.16 Z" />
                        </g>
                    </svg>
                )

            case 'pdfActive':
                return (
                    <svg width="55" height="55" viewBox="2 2 51 51" className="pdfActive">
                        <g fill="none" fillRule="evenodd" strokeWidth="2">
                            <polyline stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" points="22.22 35.028 33.14 35.028 33.025 13.449" transform="rotate(45 27.68 24.238)" />
                        </g>
                    </svg>
                )

            case 'pdfRemove':
                return (
                    <svg width="55" height="55" viewBox="2 2 51 51" className="pdfRemove">
                        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                            <g stroke="#FFF" strokeLinecap="round" strokeWidth="2" strokeLinejoin="round" transform="translate(18 18)">
                                <path d="M8.55781463,19.8480293 L8.44252081,-1.73072577" transform="rotate(45 8.5 9.059)" />
                                <path d="M0.830163182,1.47017226 L16.1701723,16.6471313" />
                            </g>
                        </g>
                    </svg>
                )

            case 'lens':
                return (
                    <svg width="55" height="55" viewBox="2 2 51 51">
                        <g fill="none" fillRule="evenodd" transform="rotate(-90 27.5 26.5)">
                            <g transform="rotate(-45 42.038 3.32)">
                                <path className="stroke" stroke="#E8247E" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.4120383,8.47258766 L1.0658141e-13,8.47258766" />
                                <circle className="fill" cx="19.814" cy="8.806" r="8.806" fill="#E8247E" />
                            </g>
                            <path fill="#FFF" className="fillReverse" d="M29.16,25.972 L29.16,23.748 L26.888,23.748 C26.408,23.748 26.008,23.364 26.008,22.868 C26.008,22.388 26.408,21.988 26.888,21.988 L29.16,21.988 L29.16,19.764 C29.16,19.252 29.56,18.852 30.072,18.852 C30.568,18.852 30.984,19.252 30.984,19.764 L30.984,21.988 L33.256,21.988 C33.736,21.988 34.136,22.388 34.136,22.868 C34.136,23.364 33.736,23.748 33.256,23.748 L30.984,23.748 L30.984,25.972 C30.984,26.484 30.568,26.884 30.072,26.884 C29.56,26.884 29.16,26.484 29.16,25.972 Z" />
                        </g>
                    </svg>
                )

            case 'info':
                return (
                    <svg width="55" height="55" viewBox="2 2 51 51">
                        <g>
                            <path d="M26.4,33.9v-8.4c0-0.6,0.5-1.2,1.2-1.2c0.6,0,1.2,0.5,1.2,1.2v8.4c0,0.6-0.5,1.2-1.2,1.2
                                C26.9,35.1,26.4,34.6,26.4,33.9z" fill="#E8247E" className="fill" />
                            <path fill="#E8247E" className="fill" d="M27.5,11.5c0.8,0,1.3,0.6,1.3,1.3v0c0,0.7-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3v0C26.2,12.1,26.8,11.5,27.5,11.5
                                    z"/>
                        </g>
                    </svg>
                )

            case 'play':
                return (
                    <svg width="55" height="55" viewBox="1 1 44 44">
                        <polygon strokeWidth="2" className="stroke fill" stroke="#E8247E" fill="none" strokeMiterlimit="10" points="18.17 14.15 18.17 30.85 29.71 22.5 18.17 14.15" />
                    </svg>
                )

            case 'soundoff':
                return (
                    <svg viewBox="0 0 43.05 41.98">
                        <path d="M24,6C21.2,8.6,13.6,17.2,13.6,17.2l-6.4-.1A1.76,1.76,0,0,0,5,19c-.1,2.4,0,5.8,0,5.8s-.1,3.4,0,5.8a1.76,1.76,0,0,0,2.2,1.9l6.4-.1S21.3,41.1,24,43.6s3.5-.3,3.5-.3V6.4S26.8,3.5,24,6Z" transform="translate(-3.96 -3.7)" fill="none" stroke="#E8247E" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
                        <line x1="9.64" y1="13.6" x2="9.64" y2="28.8" fill="none" stroke="#E8247E" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
                        <path d="M34.5,17.3a7.34,7.34,0,0,1,5.8,7.6,7.34,7.34,0,0,1-5.8,7.6" transform="translate(-3.96 -3.7)" fill="none" stroke="#E8247E" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
                        <path d="M34.5,9.7s11.6,2,11.5,15.2S34.5,40.1,34.5,40.1" transform="translate(-3.96 -3.7)" fill="none" stroke="#E8247E" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
                    </svg>
                )

            case 'soundon':
                return (
                    <svg viewBox="0 0 43.05 41.98">
                        <path d="M33.25,19.4a2.49,2.49,0,0,0,.59.29,5.35,5.35,0,0,1,1.85,1.16,5,5,0,0,1,1.5,4.93,5.12,5.12,0,0,1-2.77,3.56l1.44,1.13a6.33,6.33,0,0,0,1.29-1.09A7,7,0,0,0,39,24.46a7.91,7.91,0,0,0-.08-.93,7,7,0,0,0-2.1-3.93,6.63,6.63,0,0,0-3-1.67.77.77,0,0,0-.9.48A.76.76,0,0,0,33.25,19.4Z" transform="translate(-3.96 -3.7)" fill="#E8247E" />
                        <path d="M16.25,15.19l2.39-2.54,2.75-2.9,3.84-4a.89.89,0,0,1,1.44,0,.48.48,0,0,1,.1.23,1.33,1.33,0,0,1,0,.2V23.39l1.68,1.3V6a1.64,1.64,0,0,0-.23-.87,2.69,2.69,0,0,0-1.62-1.3l-.38-.08h-.78a2.19,2.19,0,0,0-1,.47c-.35.31-.68.65-1,1q-2,2.12-4,4.24c-.87.92-1.73,1.86-2.6,2.78l-1.87,2Z" transform="translate(-3.96 -3.7)" fill="#E8247E" />
                        <path d="M45.94,23c0-.2,0-.4-.06-.6A14.22,14.22,0,0,0,40,13.16a13.07,13.07,0,0,0-3-1.57,1.74,1.74,0,0,0-.67-.13.77.77,0,0,0-.67.68.76.76,0,0,0,.39.83,2.31,2.31,0,0,0,.35.18,12.64,12.64,0,0,1,4.14,2.65,10.18,10.18,0,0,1,1.89,2.25,12.19,12.19,0,0,1,1.89,5.83,12.32,12.32,0,0,1-.16,3,12.47,12.47,0,0,1-4,6.92l1.36,1c.33-.31.66-.64,1-1a14.47,14.47,0,0,0,3.34-6.83c0-.28.09-.57.14-.85v-3A2.47,2.47,0,0,1,45.94,23Z" transform="translate(-3.96 -3.7)" fill="#E8247E" />
                        <path d="M26.77,28.87V43.14a.71.71,0,0,1-.5.68.68.68,0,0,1-.84-.09c-.66-.66-1.32-1.32-2-2L19.27,37.3c-1.39-1.47-2.74-2.95-4.16-4.38a2.12,2.12,0,0,1-.66-1.66c0-4,0-8,0-12L12.8,18c0,2.22,0,4.43,0,6.65v6.72c0,.32,0,.32-.32.32h-6a.86.86,0,0,1-.77-.79q0-4.8,0-9.6c0-.79,0-1.58,0-2.38,0-.22,0-.45,0-.67a.56.56,0,0,1,.45-.5,4.06,4.06,0,0,1,.78-.1c1.76,0,3.53,0,5.29,0l-2.2-1.72H8.33l-2.2,0a1.46,1.46,0,0,0-.39.12,2.93,2.93,0,0,0-1.6,1.59c0,.08-.09.16-.13.24V31.42a4.53,4.53,0,0,1,.27.48A2.9,2.9,0,0,0,6,33.35a.47.47,0,0,0,.17,0l6.89,0a.42.42,0,0,1,.36.16c1,1.1,2.06,2.19,3.09,3.29l2.57,2.7q2.16,2.3,4.33,4.58c.28.31.59.59.9.89a2,2,0,0,0,1,.52s.06,0,.09.05h.74a.49.49,0,0,1,.16-.05A2.2,2.2,0,0,0,27.61,45a2.45,2.45,0,0,0,.84-1.81q0-6.49,0-13Z" transform="translate(-3.96 -3.7)" fill="#E8247E" />
                        <path d="M36.22,36.24l-.21.11a.71.71,0,0,0-.35.77.74.74,0,0,0,.57.67,1.18,1.18,0,0,0,.67-.06l.81-.34Z" transform="translate(-3.96 -3.7)" fill="#E8247E" />
                        <path d="M44.12,39.54l-.1.14c-.29.37-.68.55-.87.41L6.26,11.35c-.19-.15-.11-.57.18-.95l.1-.13c.29-.37.68-.55.87-.41L44.3,38.59C44.5,38.75,44.42,39.17,44.12,39.54Z" transform="translate(-3.96 -3.7)" fill="#E8247E" fill-rule="evenodd" />
                    </svg>
                )
        }

    }


    return (
        getIcon()
    )
}

export default Icon;