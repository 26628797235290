import React, { Component } from 'react'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import Icon from '../../icon/Icon'

const Container = styled.div`
	position: relative;
	width: ${props => props.sizes.colWidth}px;
	height: ${props => props.sizes.colWidth}px;
	margin:  ${props => props.sizes.space/2}px;
	background: no-repeat center center;
    background-size: contain;
	background-image: url(${props => props.background}); 
`

//background-color: ${props => (props.isDragging ? 'lightgreen' : 'white')};

class Task extends Component {
	render() {
		return (
			<Draggable draggableId={this.props.task.id} index={this.props.index}>
				{(provided, snapshot) => (
					<Container
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						innerRef={provided.innerRef}
						ref={provided.innerRef}
						isDragging={snapshot.isDragging}
						background={this.props.task.thumbnailUrl}
						sizes={this.props.sizes}
						
					>
						<span 
							className="icon main pdf topRight selected aniHover" 
							style={{width: this.props.sizes.iconWidth, height: this.props.sizes.iconWidth}}
							onClick={() => this.props.removeImage(this.props.task)}>
							<Icon type="pdfRemove" />
						</span>
						<span className="hoverArea aniHover" />
					</Container>
				)}
			</Draggable>
		)
	}
}

export default Task;