import React, { Component } from 'react'
//import LazyLoad from 'react-lazy-load'
import LazyImage from '../LazyImage'
import { ScaleSize } from '../../Functions'

class Team extends Component {
	constructor(props) {
 		super(props);
 		
 		this.state = {
 			cols: 4
        };   
 	}

 	componentDidMount = () => {
        window.addEventListener('resize', this.setSizes)
        this.setSizes();
    }

    setSizes = () => {     
    	this.setState({
            cols: this.component.clientWidth > 1000 ? 4 : this.component.clientWidth >= 720 ? 3 : this.component.clientWidth >= 320 ? 2 : 1
        })

    }

    handleTouch = (ID) => {
 		console.log(ID);

 		let touchedID = this.state.touchedID === ID ? null : ID 

 		this.setState({
           touchedID: touchedID
        })
 	}

    componentWillUnmount = () => {
    	console.log('unmount')
        window.removeEventListener('resize',  this.setSizes)
    }

	render() {
		let data = this.props.data
		let colHeights = []

		for(let i=0; i<this.state.cols; i++) {
			colHeights.push(0)
		}

		let grid = []

		let style = {...this.props.style,
			title: {
				fontSize: ScaleSize(28, 18, this.props.style.scale),
				lineHeight: ScaleSize(32, 22, this.props.style.scale)
			}
		}

		if(this.component) {

			const addY = this.component.getBoundingClientRect().y + window.scrollY

			let componentWidth = this.component.clientWidth,
			space = 0.03 *componentWidth,
			colWidth = (componentWidth - (this.state.cols-1) * space) / this.state.cols

			data.team.forEach((image, i) => {
		    	
				let currentCol = 0;

				for(var j=1; j<=colHeights.length; j++) {
					if(colHeights[j]<colHeights[currentCol]) {
						currentCol = j;
					}
				}

				let left = currentCol * colWidth + currentCol * space;
				let top = colHeights[currentCol]
				let width = colWidth
				let height = colWidth + 116

				if(image.type==='person') {

					colHeights[currentCol] += height 

					grid.push(
						<div
							className={'person aniResize' + (this.state.touchedID === i ? ' touched' : '')}
							key={i}
							style={{
								left: `${left}px`, 
								top: `${top}px`, 
								width: `${width}px`,
								height: `${height}px`
							}}
							onTouchStart={e => this.handleTouch(i)}
							onTouchEnd={e => e.preventDefault()}
							>
							<div className="image" style={{backgroundColor: image.color}}>
								<div className="hover">
									<div className="table">
										<div className="col">
											<p style={style.title}>{image.position}</p>
											<a href={`mailto:${image.email}`}>{image.email}</a>
										</div>
									</div>
								</div>
								<LazyImage top={top+addY} url={image.image} alt={image.caption} />
							</div>
							<h4>{image.name}</h4>
						</div>
					)
				}

				if(image.type==='image') {

					if(currentCol+image.width<=this.state.cols) {
						
						width = image.width*colWidth + (image.width-1)*space
						height = (image.height*height)-116 

						grid.push(
							<div
								className="image"
								key={i}
								style={{
									left: `${left}px`, 
									top: `${top}px`, 
									width: `${width}px`,
									height: `${height}px`,
									backgroundImage: `url(${image.image})`
								}} />
						)

						for(j=currentCol; j<currentCol+image.width; j++) {
							
							colHeights[j] += height+116 
						}

					}

				}

			});
		}

		let height = Math.max(...colHeights)

		//console.log(colHeights);

		return (
			<div className="content team">
				<div className="center main">
					<div ref={component => this.component = component}>			
						<div className="grid" style={{height: `${height}px`}}>
							{grid}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Team;
