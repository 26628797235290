export const contentConstants = {
    GETCONFIG_REQUEST: 'CONTENT_GETCONFIG_REQUEST',
    GETCONFIG_SUCCESS: 'CONTENT_GETCONFIG_SUCCESS',
    GETCONFIG_FAILURE: 'CONTENT_GETCONFIG_FAILURE',
    GETPAGE_REQUEST: 'CONTENT_GETPAGE_REQUEST',
    GETPAGE_SUCCESS: 'CONTENT_GETPAGE_SUCCESS',
    GETPAGE_FAILURE: 'CONTENT_GETPAGE_FAILURE',
    RESIZE: 'CONTENT_RESIZE',
    SET_GALLERY: 'CONTENT_SET_GALLERY',
    SET_LANGUAGE: 'CONTENT_SET_LANGUAGE',
    SET_COOKIES: 'CONTENT_SET_COOKIES',
};