import { pdfConstants } from '../constants';

const pdfImages = JSON.parse(localStorage.getItem('pdfImages'));

const initialState = {
    pdfImages: pdfImages ? pdfImages : [],
}

export function pdf(state = initialState, action) {
	switch (action.type) {
        case pdfConstants.ADDREMOVE_IMAGE:

            let pdfImages = [...state.pdfImages]
            const index = pdfImages.findIndex(entry => entry.ID === action.image.ID)
    
 
            if(index>-1) {
                pdfImages.splice(index, 1)
            } else {
    
                let test = {
                    ID: action.image.ID,
                    thumbnailUrl: action.image.thumbnailUrl
                }
    
                pdfImages.push(test)
            }

            localStorage.setItem('pdfImages', JSON.stringify(pdfImages));

            return {
                ...state,
                pdfImages
            }

        case pdfConstants.UPDATEALL_IMAGES:

            localStorage.setItem('pdfImages', JSON.stringify(action.images));

            return {
                ...state,
                pdfImages: action.images
            }

        default:
            return state
    }
}